// base color variables
$color-white: #ffffff;
$color-black: #000000;

$header-bg: #f2f2f2;

$body-grey: #d9d9d9;
$section-light: #f3f3f3;

// $disabled: #747474;
$select-title: #d9d9d9;
$select-placeholder: #6c757d;
$grey-input: #d9d9d9;
$input-pre_color: #6c757d;
$input-otp_border: #ced4da;

$logo-border: #d7d7d7;
$logo-color: #858585;

$footer-heading: #bdbdbd;


$body-base_color: #F7F9FD;
$input-outline: #838686;
// $primary-color: #009B93;
// $disabled: #7ccbc7;
$label-color: #404040;
// $active-label: #2D338D;
// $secondary-color: #505389;

$primary-color: #2C3394;
$primary-alpha-light: #565BA5;
$primary-alpha: #505389;
$primary-gama-light: #97979B;
$primary-light: #EFF0F8;
$secondary-color: #009B94;
$secondary-alpha: #7ccbc7;


// media variables
$xxl: 1400px;
$xl: 1200px;
$lg: 992px;
$sm: 576px;