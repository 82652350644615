@import '../../styles/typography.scss';
@import '../../styles/variables.scss';

.article-section {
    h2 {
        @extend .headingFont;
    }

    p {
        @extend .txtRegular;
    }

    button {
        padding: 6px 22px;
    }
}