@import "../../styles/variables.scss";

.footer-container {
    // position: fixed;
    bottom: 0;
    background-color: #f9f9f9;
    width: 100%;

    footer {
        padding: 20px 0;

        a{
            color: $secondary-color;
        }

        p {
            font-size: 12px;
            text-align: left;
            line-height: 1.8;
            margin-top: 15px;
            width: 75%;
        }

        h3 {
            color: $footer-heading;
            text-align: left;
        }

        h5 {
            margin: inherit !important;
        }

        ul {
            list-style: none;
            text-align: left;
            padding: 0;

            li {
                font-size: 14px;
                margin-bottom: 10px;
            }
        }

        i {
            font-size: 23px !important;
            width: 30px;
            text-align: center;
            margin-bottom: 10px;
        }
    }
}

@media only screen and (max-width: 600px) {
  .footer-container {
    position: unset !important;
  }
}