@import "../../styles/typography.scss";
@import "../../styles/variables.scss";

.publish-section {
    background-color: #f9f9f9;

    h2 {
        @extend .headingFont;
    }

    p {
        @extend .txtRegular;
    }

    button {
        padding: 6px 22px;
    }

    .publish-box {
        position: relative;
        margin-bottom: 20px;
    }

    .publish-row {
        display: flex;

        h4 {
            @extend .sectionheadingFont;
        }

        i {
            height: 80px;
            justify-content: center;
            align-items: center;
            display: flex;
            background-color: #f3f3f3;
            border-radius: 50%;
            margin-top: -19px;
            position: absolute;
            left: 0;
            width: 77px;
            font-size: 1.6rem;
        }

        .description {
            margin-left: 50px;
            z-index: 999999;
            padding-right: 66px;
        }
    }
}