@import "../../../styles/typography.scss";
@import "../../../styles/variables.scss";

.personal-details {
  padding-top: 25px;
  label {
    @extend .inputLabel;
    padding: 0 0 0 0px;
  }
.ml-1{ margin-left: 5px;} 
  .py-2 {
    padding: 0 !important;
  }
  .form-check .form-check-input {
     margin-right: 6px;
     border: 0;
     background-color: rgba(0, 155, 148, 0.13);
    }


  .grey-row {
    background-color: #f6f6f6;
    padding: 12px 15px 12px 15px;
    border-radius: 8px;
    margin-bottom: 20px;
  }

  button {
    padding: 6px 22px;
  }

  button:disabled {
    opacity: 0.5;
  }

  p.secondaryAff-subtext {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 113.1%;
    /* identical to box height, or 16px */
    color: #404040;
  }
  
  p {
    font-family: "Inter";
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #404040 !important;
  }

  h6 {
    @extend .sectionheadingFont;
  }

  .profile-upload {
    min-width: 80px;
    height: 80px;
    background-color: $secondary-color;
    border-radius: 50%;
    font-size: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: $color-white;
    overflow: hidden;
    img {
      width: 80px;
      height: 80px;
      border-radius: 50%;
    }
    & .profile-uploadImg {
      background-size: cover;
      height: 100%;
      width: 100%;
      background-position: center;
    }
  }

  .inner-container {
    padding: 0 70px;
  }

  .link {
    @extend .linkFont;
     
    &.rmv-btn {
      color: $primary-gama-light;
    }
  }

  .heading {
    @extend .midHeading;
    color: $primary-color;
    font-weight: 800;
  }

  .label {
    @extend .labelFont;
  }

  .shadow-box {
    box-shadow: 0px 0px 14px rgba(0, 155, 148, 0.09);
    border-radius: 20px;
    padding: 30px 54px;
    background-color: #ffffff;
  }
  .nav-item {
    a {
      color: $primary-alpha-light;
      &.active {
        color: $primary-color;
      }
    }
  }
  a {
    border-radius: 10px;
    color:#404040;
    font-weight: 600;
    // opacity: 0.7;
  }

  a.active {
    background: rgba(45, 51, 141, 0.12);
    color: $primary-color;
    font-weight: 600;
    opacity: 1;
  }

  a:hover {
    color: inherit;
  }

  .progress{
    background: rgba(89, 89, 89, 0.09);
    border-radius: 10px;
    width: 20% !important;
  }

  .progress-container {
    color: $primary-alpha-light;
    font-weight: 500;
    margin-left: 15px;
    // opacity: 0.5;

    .progress-bar {
      background: linear-gradient(266.07deg, #fcbf46 19.23%, #e8e689 114.13%);
      border-radius: 10px;
    }
  }
.rowManage { padding: 0 10px;}
.rowManageHead { padding: 0 10px;}
  .optional-badge {
    color: $primary-color;
    background-color: #e6ebef;
    font-weight: 400;
    font-style: italic;
    font-size: 10px;
    line-height: 12px;
    font-family: 'Inter';
    color: #2D338D;
  }

  .w-20 {
    width: 20%;
  }

  .w-80 {
    width: 80%;
  }

  .title_with_name {
    display: flex;
    align-items: baseline;
  }

  .tel-pd {
    padding: 9px 12px 11px 70px !important;
  }

  .tel-active::before {
    position: absolute;
    left: 29px;
    top: 35px !important;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: $primary-color;
    z-index: 1;
  }

  .tel-fade {
    position: absolute;
    left: 29px;
    top: 31px;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #97979b !important;
    opacity: 0.5;
    z-index: 1;
  }

  .tel-disable {
    position: absolute;
    left: 29px;
    top: 32px;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #97979b;
    z-index: 1;
  }

  .outer-link {
    position: absolute;
    right: -70px;
    top: 30px;
  }
  .inner-link {
    position: absolute;
    right: 8px;
    top: 39px;
  }
  .gender_row {
    flex-wrap: wrap;
    margin-top: 5px;
  }
}

.logo_container {
  img {
    width: 234.96px !important;
    margin-left: 58px;
    margin-top: 2px;
  }
}

.ps-10 {
  padding: 0 0 0 10px;
}

.gender_row {
  .form-check {
    display: flex;
    align-items: center;
    margin-right: 40px !important;
  }

  label {
    color: #000000;
    font-weight: 600;
    margin: 8px 0 0 0;
    font-size: 14px;
  }
}
.dividerBox { 
  border-top: 0.3px solid $color-black;
  padding-top: 15px;
  & .gender_row .form-check {
    padding-top: 0 !important;
  }
  & .secondaryAff-subtext {
    margin-bottom: 20px;
  }

}
.br_divide {
  border-top: 0.3px solid $color-black;
  margin-bottom: 15px;
  margin-top: 15px;
}

.talk-to-us_btn {
  background: $primary-color;
  box-shadow: 0px 0px 16px rgb(0 0 0 / 14%);
  border: none;
  color: $color-white;
  padding: 5px 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 3px;
  line-height: 113.1%;
  position: fixed;
  right: 58px;
  bottom: 35px;
  text-align: left;

  h4 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 113.1%;
    margin: 0;
  }

  span {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 113.1%;
  }
}

.mx-54 {
  margin-right: 54px;
}

.upload-profile-picture-with-description {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

@media screen and (max-width: 600px) {
  .personal-details {
    a{
      padding: 4px 4px;
      font-size: 12px;
    }
    img{
      margin: 0 8px !important;
    }
  }
  .centerSection{
    padding-top: 50px !important;
  }
}
@media screen and (max-width: 767px){
  #personal_details{
    padding: 0 16px;
  }
  .centerSection{
    padding-top: 50px !important;
  }
  .logo_container{
    img{
      width: 115px !important;
    }
  }
}

.success-modal{
  & .modal-dialog {
    width: auto !important;
  }
  & .modal-footer {
    margin-top: 13px;
  }
}
.labelFont, .personal-details .label.doNot{
  font-size: 12px ;
}

.logout-dropdown_container{
  button{
    background: transparent;
    border: none;
    color: #009B94;
    &::after{
      content: none;
    }
    .dropdown-menu{
      &:hover{
        background: transparent;
      }
      a{
        color: #ACACAC;
      }
    }
  }
  .dropdown-item{
    &:hover{
      background-color: transparent;
      color: #ACACAC;
    }
  }
  a{
    color: #ACACAC;
  }
}

.grp-subject {
  display: flex;
  justify-content: space-between;

  a:nth-child(2) {
    position: relative;
    bottom: 56px;
    left: 45px;
    font-size: 16px !important;
    text-decoration: underline;
    border-radius: 10px;
    color: #404040;
    font-weight: 600;
  }
 
  a:nth-child(2):hover {
    color: #404040 !important;
  }
}

.another-subject {
  a {
    position: relative;
    bottom: 56px;
    left: 45px;
    font-size: 16px !important;
    text-decoration: underline !important;
    border-radius: 10px;
    color: #404040 !important;
    font-weight: 600;
  }

  a:hover {
    color: #404040 !important;
  }
}