.modal-content {
    background-color: $light;
    box-shadow: 0 0 40px rgba($dark, 0.09);
    border-radius: 20px;
    border: none;
}

.modal-header {
    flex-wrap: wrap;
    text-align: center;
    justify-content: center;
    border-bottom: none;

    & .link-back {
        position: absolute;
        left: 25px;
        top: 18px;
    }

    & p {
        font-weight: 400;
        font-size: 14px;
        color: $dark-400;
    }
    
    & .good-news {
        font-family: "Inter";
        font-style: normal;
        font-weight: 700;
        font-size: 22px;
        line-height: 24px;
        color: #2C3394;
        margin-bottom: 10px;
    }

    & .btn-close {
        position: absolute;
        right: 30px;
        top: 30px;
        padding: 0;
        font-size: 14px;
        z-index: 99;
        display: inline-block;
        width: 20px;

        &:hover,
        &:active {
            outline: none;
            box-shadow: none;
        }
    }
}

.btn-skip {
    padding: 0;
    font-size: 18px;
    color: $dark-900;
    text-decoration: none;
    font-weight: 600;
    text-decoration: underline;

    &:hover,
    &:active {
        outline: none;
        box-shadow: none;
        color: $primary;
    }
}

.smallSubText {
    font-size: 14px;
    font-weight: 400;
    color: $primary;
    font-style: italic;
    display: block;
}

.bigSubText {
    font-weight: 600 !important;
    color: $dark !important;
    font-size: 16px !important;
}

.modal-icon {
    width: 100px;
    margin: 0 auto;
    margin-top: -62px;
    border-radius: 50%;
    background-color: $light;

    & img {
        display: block;
        max-width: 100%;
    }
}

.modal-title {
    width: 100%;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: $secondary;
    margin-top: 15px;
    margin-bottom: 5px;
    padding: 0 25px;

    &.bigText {
        font-size: 160% !important;
    }

}

.smallSubText+.modal-title {
    margin-top: 8px !important;
}

.modal-body {
    // padding-top: 20px;
    padding-bottom: 20px;
    position: relative;

    & .btn-skip {
        position: absolute;
        right: 25px;
        top: 25px;
    }
}

.modal-footer {
    justify-content: center;
    border-top: none;
    position: relative;

    & .btn-skip {
        position: absolute;
        right: 18px;
        margin: 0;
        bottom: 25px;
    }
}

.modal-dialog {
    max-width: 460px;
}

.modal-sm {
    max-width: 375px;
}

.modal-lg {
    max-width: 800px;
}

.modal-xl {
    max-width: 80%;
}

.link-back {
    color: $primary;
    text-decoration: none;
    font-size: 14px;
    font-weight: 700;
    display: inline-flex;
    align-items: center;
    cursor: pointer;

    &:hover {
        opacity: .85;
        color: $primary !important;
    }

    & b {
        font-size: 100%;
        margin-right: 5px;
        font-weight: bold;
        margin-bottom: -2px;
    }
}


/********************************/

/** PROFILE MODAL **/
.profileModal {
    &-opt {
        margin: 0 -10px;
        text-align: center;
        display: flex;
        padding-bottom: 20px;
        justify-content: center;

        & a {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            background-color: rgba($primary, .1);
            color: $primary !important;
            padding: 25px;
            font-size: 14px;
            font-weight: 700;
            margin: 0 10px;
            border-radius: 10px;
            text-decoration: none;
            cursor: pointer;
            width: calc(50% - 20px);

            &:hover {
                opacity: .85;
            }

            & b {
                font-size: 150%;
                margin-right: 5px;
            }

        }
    }

    &-note {
        font-size: 14px;
        color: $dark;
        padding: 20px 0;
        border-top: 1px solid $dark-900;

        & h2 {
            font-size: 14px;
            font-weight: 600;
            margin-bottom: 5px;
        }

        & ol {
            padding-left: 20px;

            & li {
                font-weight: 400;
                padding: 1px 0;
            }
        }
    }
}

.uploadBtn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: rgba($primary, .15);
    color: $primary !important;
    padding: 25px;
    font-size: 14px;
    font-weight: 700;
    margin: 0 10px;
    border-radius: 10px;
    text-decoration: none;
    cursor: pointer;
    width: calc(50% - 20px);

    &:hover {
        opacity: .85;
    }

    & b {
        font-size: 150%;
        margin-right: 5px;
    }
}

.imgWrap {
    position: relative;
    border-radius: 10px;
    height: 58px;
    margin-bottom: 20px;

    &-opt {
        position: absolute;
        right: -6px;
        top: -10px;
        width: 18px;

        & a {
            background-color: $primary;
            color: $light !important;
            width: 18px;
            height: 18px;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            font-size: 10px;
            padding: 0;
            cursor: pointer;
            text-decoration: none;
        }
    }

    & img {
        width: 100%;
        height: 100%;
        display: block;
        object-fit: cover;
        overflow: hidden;
        border-radius: 10px;

    }
}

.urlWrap {
    padding: 18px 20px;
    border-radius: 10px;
    font-size: 14px;
    color: $dark;
    font-weight: 600;
    padding-left: 40px;
    position: relative;
    background-color: #f7f7f7;
    margin-bottom: 20px;

    &-icon {
        position: absolute;
        left: 15px;
        top: 50%;
        transform: translateY(-50%);
    }

    &-url {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    &-opt {
        position: absolute;
        right: -10px;
        top: -10px;
        width: 18px;

        & a {
            background-color: $primary;
            color: $light !important;
            width: 18px;
            height: 18px;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            font-size: 12px;
            padding: 0;
            cursor: pointer;
            text-decoration: none;
        }
    }
}

.paymentIns {
    padding-left: 28px;
    position: relative;
    text-align: left;
    max-width: 410px;
    display: inline-table;

    &-icon {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 26px;
        height: 26px;
        border: 2px solid $primary;
        border-radius: 50%;
        font-size: 16px;
        color: $primary;
        position: absolute;
        left: 0;
        top: 2px;
        font-weight: 500;
    }

    &-text {
        font-size: 10px;
        color: $dark;
        font-family: $familySecondary, $fallbackfont;
        font-style: italic;
        padding: 0 10px;

        & a {
            font-weight: 600;
            font-style: normal;
            color: $primary !important;
            cursor: pointer;

            &:hover {
                text-decoration: underline;
            }
        }
    }
}

.paymentMsg {
    border-radius: 10px;
    background: rgba(64, 64, 64, 0.06);
    padding: 15px 20px;
    max-width: 410px;
    display: inline-table;
    text-align: left;

    & h2 {
        font-weight: 600;
        font-size: 14px;
        color: $dark;
        margin-bottom: 10px;
    }

    & p {
        font-weight: 400;
        font-size: 12px;
        color: $dark-400;
        font-family: $familySecondary, $fallbackfont;

    }

    & small {
        margin-top: 6px;
        font-size: 10px;
        color: $dark-400;
        font-weight: 700;
        display: block;
    }

    & a {
        font-weight: 600;
        font-size: 14px;
        color: $primary;
        text-decoration: underline;

        &:hover {
            opacity: .85;
        }
    }

    & .styledCheck {
        font-size: 12px;
    }
}

.sampleEnsure {
    padding: 16px;
    border: 1px solid $primary;
    border-radius: 10px;
    background-color: #F2F2F2;
    color: $dark;

    & h5 {
        font-size: 12px;
        font-weight: 700;
        color: $dark;
        margin-bottom: 8px;

        & b {
            font-size: 110%;
            color: $primary;
        }
    }

    & ul {
        font-size: 12px;
        padding-left: 15px;

        & li {
            padding: 2px 0;

            &:first-child {
                padding-top: 0;
            }

            &:last-child {
                padding-bottom: 0;
            }
        }
    }

}

/** CONGRATS MODAL **/
.congratsModal {
    color: $dark;

    & img {
        margin-bottom: 10px;
    }

    & h5 {
        font-weight: 700;
        font-size: 30px;
        margin: 5px 0;
    }

    & p {
        font-size: 22px;
        font-weight: 600;
    }

    & span {
        color: $dark-400;
        font-size: 20px;
    }


}

.rewardNote {
    padding-top: 15px;
    padding-bottom: 15px;

    & small {
        display: block;
        font-size: 12px;
        font-weight: 600;
        color: $dark-400;
        text-align: center;

        img {
            background-color: white;
            border-radius: 50%;
            border: 1.5px solid #BF1E2E;
            padding: 2px 6px;
            margin-right: 8px;
            height: 20px;
            width: 22px;
        }
    }

}

/** FEEDBACK **/
.feedbackContent {
    text-align: center;

    & p {
        font-size: 16px;
    }

    & label {
        font-size: 13px;
        display: block;
        padding-bottom: 4px;
        font-weight: 600;
        color: $primary;
    }

    & textarea {
        width: 100%;
        border-radius: 10px;
        border: 1px solid rgba($dark, .04);
        background-color: rgba($dark, .02);
         font-size: 14px;
         padding:10px 15px;
    }
}

.feedbackReward {
    width: 100%;
    font-size: 18px;
    font-weight: 500;
    color: $dark;
    text-align: center;
    line-height: normal;
    padding-top: 20px;
    padding-bottom: 10px;
    margin: 0;

    & b {
        color: rgba($primary, 1);
        font-size: 30px;
        display: block;
        margin-top: 5px;
    }
}

.feedbackSmiley {
    & ul {
        display: flex;
        justify-content: center;
        padding-top: 20px;

        & li {
            list-style: none;
            padding: 0 7px;
        }
    }

    & .smileyWrap {
        position: relative;

        & input {
            opacity: 0;
            position: absolute;
        }

        & span {
            width: 60px;
            height: 60px;
            border-radius: 50%;
            background-color: $light;
            box-shadow: 0 0 14px rgba($dark, .08);
            border: 5px solid $light;
            display: block;
            cursor: pointer;

            &:hover {
                opacity: .85;
                border-color: $primary;
            }
        }

        & img {
            max-width: 100%;
            display: block;
        }

        & input:checked+span {
            border-color: $primary;
        }
    }
}

/** SHARE **/
.shareModal {
    position: relative;
    padding-top: 22px;

    &::before {
        content: '';
        position: absolute;
        left: 50%;
        top: 0;
        transform: translateX(-50%);
        width: 40%;
        height: 1px;
        background-color: rgba($dark-900, .4);
    }


    & p {
        font-weight: 300;
        font-style: italic;
        color: $dark;
        font-size: 16px;
    }

    & p+span {
        color: $primary;
        font-size: 22px;
        font-weight: 700;
        display: block;
    }

    & small {
        display: block;
        font-size: 14px;
        font-weight: 400;
        color: $dark;
        padding-bottom: 10px;

        &:last-child {
            font-size: 12px;
            color: $dark-400;
            font-weight: 600;
            text-align: left;
        }
    }

    & .refer-earn {
        font-weight: 600;
        color: #000;
        font-size: 11px;
        margin-top: 10px;
    }
}

.divideLine {
    position: relative;

    & b {
        font-size: 12px;
        font-weight: 400;
        color: $dark-400;
        display: inline-flex;
        padding: 0 4px;
        background-color: #FFF4F4;
        position: relative;
        z-index: 1;
    }

    &::before {
        content: '';
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 40%;
        height: 1px;
        background-color: rgba($dark-900, .4);
        margin-top: 1px;
    }
}

.codeBelow {
    padding: 20px 0;

    & small {
        color: $dark;
        font-weight: 600;
        font-size: 14px;
    }
}

.socialShare {
    margin-top: 20px;

    & ul {
        display: flex;
        justify-content: center;
        padding: 15px 0;
        padding-top: 5px;

        & li {
            list-style: none;
            padding: 0 10px;

            & a {
                width: 48px;
                height: 48px;
                background-color: $light;
                border-radius: 50%;
                box-shadow: 0 0 14px rgba($dark, .08);
                display: flex;
                padding: 5px;
                cursor: pointer;
                text-decoration: none;

                &:hover {
                    opacity: .85;
                }

                & img {
                    display: block;
                    max-width: 100%;
                }
            }
        }
    }
}

.mudrakoshBox {
    text-align: center;

    & p {
        font-size: 32px;
        font-weight: 600;
        text-align: left;
        color: $primary;
        display: inline-block;
        vertical-align: middle;
        line-height: 34px;
        max-width: 200px;
    }

    & span {
        display: inline-block;
        vertical-align: middle;
    }

}


.mudrakoshBox+.divideWrap {
    width: 50%;
    margin: 0 auto;
    margin-top: -20px;
    padding-bottom: 20px;
}

.codeWrap {
    padding: 12px 14px;
    border: 1px dashed rgba($dark, .2);
    border-radius: 10px;
    background-color: rgba($dark, .02);
    font-weight: 700;
    font-size: 16px;
    color: $dark;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    margin: 0 auto;

    & p {
        font-style: normal;
        font-weight: inherit;

        &:last-child {
            padding-left: 25px;
            color: $primary;
            cursor: pointer;
            border-left: 1px solid rgba($dark, .2);
        }

    }

}

/** LEGAL MODAL **/
.legalModal {
    font-size: 14px;
    color: $dark;

    & a {
        color: $primary !important;
        font-weight: 700;
    }

    & small {
        font-size: 12px;

        &:first-child {
            padding: 20px 0;
            display: block;
            opacity: .85;
            padding-top: 5px;
        }
    }

    & p {
        padding-bottom: 20px;
    }

    & h3 {
        font-size: 16px;
        margin-top: 5px;
        font-weight: 700;
        margin-bottom: 5px;
    }
}

/** REFER **/

.referContent {
    border: 1px solid $primary;
    background-color: #FFF4F4;

    & .modal-header {
        background: linear-gradient(185.18deg, #F17A1F -13.44%, #EC6458 126.86%);
        border-radius: 20px 20px 0 0;

        & .bigText {
            font-size: 230% !important;
            margin-bottom: 5px;
        }

        & .bigSubText {
            font-style: italic;
            font-size: 20px !important;
            font-weight: 400 !important;
        }

        & .btn-close {
            color: $light !important;
        }

        & .mudraCount {
            width: 100%;
            text-align: center;
            padding-top: 5px;

            & span {
                display: inline-block;
                font-size: 34px;
                font-weight: 700;
                text-align: center;
                line-height: normal;
                vertical-align: middle;
                color: #FEE119;

                & b {
                    color: #FFFFFF;
                    font-weight: 700;
                    margin: 0;
                }
            }

            & b {
                display: inline-block;
                margin-left: 15px;
                vertical-align: middle;
                margin-bottom: -10px;
            }
        }
    }

    & .referStep {
        position: relative;
        padding: 10px 0;

        &:after {
            content: "";
            width: 70%;
            height: 1px;
            background-color: $primary;
            position: absolute;
            left: 50%;
            top: calc(50% - 12px);
            transform: translate(-50%, -50%);
        }

        & ul {
            display: flex;
            justify-content: center;
            align-items: center;

            & li {
                font-size: 10px;
                text-align: center;
                color: $dark;
                line-height: 12px;
                list-style: none;
                font-weight: 700;
                max-width: 100px;
                margin-right: 25px;

                &:last-child {
                    margin-right: 0;
                }

                & span {
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: $dark;
                    font-size: 22px;
                    border: 1px solid $primary;
                    margin: 0 auto;
                    background-color: #FFF4F4;
                    position: relative;
                    z-index: 1;
                    margin-bottom: 5px;
                }


            }
        }
    }
}

.attentionText {
    text-align: left;
    max-width: 76%;
    width: 100%;
    margin: 0 auto;
    font-family: $familySecondary, $fallbackfont;
    & p {
        margin: 20px 0;
        font-family: $familySecondary, $fallbackfont;
        &:first-child{
            margin-top: 0;
        }
        &:last-child{
            margin-bottom: 10px;
        }
    }
}

.profile-setup-buttons{
    display: flex;
    gap: 10px;
    margin: 0 20px;

    & .grp-buttons {
        & .button {
        width: 100% !important;
        font-size: 14px !important;
        text-transform: capitalize !important;
        padding: 12px 10px !important;

        &:hover {
            color: #FFFFFF;
        }
    }
    & a {
        width: 100%
    }
}
}

.modal-dialog .modal-title.modal-title-red {
    color: #BF1E2E !important;
}
