@import "../../styles/variables.scss";

.sidebar {
    left: 0;
    background-color: #e7e7e7;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    padding: 25px;
    text-align: left;
    overflow-y: auto;
    a{
        color: $secondary-color;
    }
}

@media (max-width: 960px){
    .sidebar{
        display: none !important;
    }
}