@import "../../styles/typography.scss";
@import "../../styles/variables.scss";

.article-services {
    h2 {
        @extend .subheadingFont;
    }

    p {
        @extend .txtRegular;
    }

    button {
        padding: 6px 22px;
    }

    ul {
        @extend .txtRegular;
        padding: 0;
        list-style-position: inside;
    }
}