body {
    position: relative;
}

.typeFont {
    font-family: $familyType;
}

.scriptFont {
    font-family: $familyScript;
}

.containWrap {
    max-width: 1204px;
    margin: 0 auto;
    clear: both;

    @media #{$desktop} {
        padding: 0 20px;
    }
}

.centerSection {
    overflow-x: hidden;
}

.innerPages {
    padding: 0 20px;
    padding-top: calc(57px + 35px);
    padding-bottom: 35px;

    @media #{$desktop} {
        padding: 0;
        padding-top: calc(92px + 85px);
        padding-bottom: 45px;
    }
}


.underLine {
    position: relative;
    display: inline-block;
    color: $primary;
    z-index: 0;

    &::before {
        content: "";
        position: absolute;
        left: 0;
        bottom: -1px;
        width: 104%;
        height: 14px;
        background: linear-gradient(270deg, rgba(227, 232, 123, 0) 0%, rgba(227, 232, 123, 0.75) 25%, rgba(255, 185, 54, 0.95) 50%, rgba(227, 232, 123, 0.75) 75%, rgba(227, 232, 123, 0) 100%);
        display: block;
        z-index: -1;
        margin: 0 -2%;
        animation: colorMove 3300ms ease-in-out infinite;
        background-size: 300% auto;

        @media #{$desktop} {
            height: 18px;

        }
    }
}

.boundaries-underline {
    position: relative;
    display: inline-block;
    color: $primary;

    &::before {
        content: "";
        position: absolute;
        left: 0;
        bottom: 4px;
        width: 104%;
        height: 14px;
        background: linear-gradient(270deg, rgba(227, 232, 123, 0) 0%, rgba(227, 232, 123, 0.75) 25%, rgba(255, 185, 54, 0.95) 50%, rgba(227, 232, 123, 0.75) 75%, rgba(227, 232, 123, 0) 100%);
        display: block;
        z-index: -1;
        margin: 0 -2%;
        animation: colorMove 3300ms ease-in-out infinite;
        background-size: 300% auto;

        @media #{$desktop} {
            height: 18px;

        }
    }
}


@keyframes colorMove {
    to {
        background-position: 300% center;
    }
}


.parallaxWrap {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: -1;
}

.parallaxElement {
    width: 350px;
    height: 350px;
    display: block;
    border-radius: 50%;
    position: absolute;
    // transition: all 300ms ease-in-out;
    opacity: 0;

    &.ele_01 {
        background-color: rgba(0, 155, 148, 0.15);
        left: 0;
        filter: blur(100px);
    }

    &.ele_02 {
        background-color: rgba(0, 155, 148, 0.35);
        right: 10%;
        filter: blur(200px);
    }

    &.ele_03 {
        background-color: rgba(0, 155, 148, 0.25);
        left: 30%;
        filter: blur(100px);
    }


    &.ele_04 {
        background: rgb(0, 155, 148);
        background: linear-gradient(180deg, rgba(0, 155, 148, .5) 0%, rgba(0, 155, 148, 0) 100%);
        left: 5%;
    }

    &.ele_05 {
        background: rgb(0, 155, 148);
        background: linear-gradient(90deg, rgba(0, 155, 148, .35) 0%, rgba(0, 155, 148, 0) 100%);
        right: 10%;
    }
}


.simpleTabs {
    margin-bottom: 10px;

    & ul {
        display: flex;
        align-items: flex-end;

        & li {
            display: flex;
            list-style: none;
            padding: 0 10px;
            padding-bottom: 5px;
            border-bottom: 2px solid rgba($dark-900, .1);

            @media #{$desktop} {
                padding: 0 15px;
            }

            &:first-child {
                padding-left: 0;
            }

            &:last-child {
                padding-right: 0;
            }

            & a {
                font-size: 15px;
                font-weight: 600;
                color: $primary !important;
                text-align: center;
                text-decoration: none;
                position: relative;

                @media #{$desktop} {
                    font-size: 26px;
                }

                &:hover {
                    opacity: .85;
                    cursor: pointer;
                }


                &.active {
                    font-size: 18px;
                    font-weight: 700;
                    line-height: 23px;

                    @media #{$desktop} {
                        font-size: 30px;
                        line-height: 40px;
                    }

                    &:hover {
                        opacity: 1;
                        cursor: default;
                    }

                    &:after {
                        content: "";
                        width: 34px;
                        height: 8px;
                        border-radius: 8px;
                        background: linear-gradient(270deg, rgba(255, 176, 27, 0.84) -2.89%, rgba(227, 232, 123, 0.84) 100%);
                        display: block;
                        position: absolute;
                        left: 0;
                        bottom: -10px;
                        z-index: 1;
                    }
                }
            }
        }
    }

}

.contentFooter {
    & .goBack {
        color: $secondary;
        font-weight: 500;
        font-size: 18px;
        text-decoration: none;
        padding-bottom: 18px;
        cursor: pointer;
        position: relative;

        & b {
            display: block;
            position: absolute;
            left: 0;
            bottom: 0;
        }
    }

    // & .helpLink {
    //     position: fixed;
    //     // left: 0;
      
    //     // top: 0;
    //     right: 60px;
    // bottom: 20px;
        
    //     padding: 6px 14px;
    // }
}

.quickFAQ {
    background-color: $light;
    box-shadow: 0 0 20px rgba($dark, .15);
    padding: 5px 14px;
    color: $primary;
    font-size: 16px;
    border-radius: 0 10px 10px 10px;
    position: fixed;
    z-index: 3;
    right: 44px;
    bottom: 20px;
    cursor: pointer;

    & p {
        font-weight: 700;
        line-height: 18px;
    }

    & span {
        font-weight: 400;
        display: block;
    }


    &:hover {
        background-color: $primary;
        color: $light;
    }
}

.twoDots {
    position: relative;

}