@import "../base/themecomponents/_variables.scss";

.contactSection {
  background-image: url(../../img/banner/banner_bg.png);
  background-position: 50% 0%;
  background-size: contain;
  background-repeat: no-repeat;

  & h1 {
    font-size: 30px;
    color: $primary;
    font-weight: 400;
    letter-spacing: -2px;
    position: relative;
    padding-left: 58px;
    margin-bottom: 10px;
    text-align: left;

    @media #{$desktop} {
      font-size: 46px;
      padding-left: 90px;
      margin-bottom: 0;
    }

    &::before {
      content: "";
      width: 50px;
      height: 20px;
      background-image: url("../../img/icons/wave.svg");
      background-repeat: no-repeat;
      background-size: contain;
      display: block;
      position: absolute;
      left: 0;
      top: 10px;

      @media #{$tablet} {
        width: 80px;
        height: 28px;
        top: 16px;
      }
    }

    & span {
      font-weight: 600;
      position: relative;
    }
    & b{ font-weight: 600; }
  }

  & .contactRow {
    display: flex;
    flex-wrap: wrap;

    @media #{$desktop} {
      flex-wrap: nowrap;
    }

    & .contactLeft {
      width: 100%;

      @media #{$desktop} {
        max-width: calc(100% - 270px);
        padding-right: 25px;
      }

      & .fieldForm::after {
        content: "*";
        color: #BE1E2D;
      }

      & .fieldWrap label {
        margin-left: 5px;
        margin-top: -20px;
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        color: black;
        display: block;
        text-align: left;
        margin-bottom: 6px;
      }

      & .fieldWrap-mobile label {
        margin-left: 5px;
        margin-top: -20px;
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        color: black;
        display: block;
        text-align: left;
        margin-bottom: 6px;
      }

      & .fieldWrap label::after {
        content: " *";
        color: #BE1E2D;
      }

      & .textarea-label::after {
        content: "*";
        color: #BE1E2D;
      }
    }

    & .contactRight {
      width: 100%;

      @media #{$desktop} {
        max-width: 270px;
      }

      & .checkOut {
        margin-top: 20px;

        @media #{$desktop} {
          margin-top: 35px;
        }
      }
    }
  }

  & .contactForm {
    padding: 30px 20px;
    background-color: $light;
    border-radius: 16px;
    border: 1px solid $secondary;
    box-shadow: 0px 0px 20px rgba(0, 155, 148, 0.13);
    margin-top: 30px;

    @media #{$desktop} {
      padding: 35px 30px;
      margin-top: 30px;
    }
    & .button{
      min-width: 154px;
      &:disabled{
        opacity: .5;
        pointer-events: none;
      }
    }
    & input.fieldForm, select.fieldForm,
    select {
      border:1px solid #858585 !important;
    }
    & textarea.fieldForm {
      min-height: 149px;
      border: 1px solid transparent;
      resize: none;
    }
  }

  & .error-field {
    float: left;
    color: #BE1E2D;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
  }

  & .textarea-label {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: black;
    margin-left: 10px;
    margin-bottom: 5px;
  }

  & .mobileCode {
    height: 36px;
    border-radius: 10px;
    background-color: #fff;
    padding: 4px 15px;
    font-size: 14px;
    color: #009b94;
    width: 100%;
    font-weight: 600;
    border: 1px solid #858585;
  }

  & .mobileCode label {
    padding: 3px 45px 0 0;
  }
}

.modal-dialog {

  & .modal-title {
    color: #2d338d;
    font-size: 22px !important;

    & .bigText {
      font-size: 28px !important;
      font-weight: 700;
    }

    & .good-news {
      font-family: "Inter";
      font-style: normal;
      font-weight: 700;
      font-size: 22px;
      line-height: 24px;
      color: #2C3394;
      margin-bottom: 10px;
    }
  }

  & .modal-body {
    text-align: center;
  }
}
sup { color: #BE1E2D; font-size: 12px; }
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{ background-color: #ffffff !important; }
.contactModalFooter {
  & .button { 
    width: 140px;
  }
}